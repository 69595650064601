import Vue from 'vue/dist/vue.js';
//import VueRouter from 'vue-router'
import App from './App.vue'
//Vue.use(VueRouter)
Vue.config.productionTip = false
import router from './router'
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
