<template>
    <div>
        <div class="top">
            connect
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style  scoped>
    .top{
        color:#fff;
    }
</style>