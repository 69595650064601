<template>
    <div class="top">

        <div class="name">
            Aakash Aggarwal. Partner at <span class="prophecy"><a href="">Prophecy</a></span>
        </div>
        <div class="header">
            Digital product designer who loves to craft positive interactions between people, their environment and technology.
        </div>

        <div class="wrapper">
            <div class="rectangle"></div>
        </div>
       
        <div class="selectedwork">Selected work.</div>
        <div class="line"></div>
        <div class="works">
            
            <router-link class="routerlink" to="">
                <div class="work work1">
                    <div class="left">
                        <div class="work-title">Loop.</div>
                        <div class="work-des">A novel and differentiated offering in the red-ocean of primary healthcare.</div>
                        <div class="work-tags">
                            <div class="tag">0 to 1</div>
                            <div class="tag">Growth Strategy</div>
                            <div class="tag">User Research</div>
                            <div class="tag">UI/UX Design</div>
                            <div class="tag">Interaction Design</div>
                            <div class="tag">Storytelling</div>
                            <div class="tag">High-fidility Prototyping</div>
                        </div>
                    </div>
                    <div class="right">
                        <img class="img-work img-work1" src="../assets/images/Loop.png" alt="">
                    </div>
                </div>
            </router-link>
            <router-link class="routerlink" to="">
                <div class="work work2">
                    <div class="left">
                        <div class="work-title">Synth.</div>
                        <div class="work-des">Tool that helps people capture, organise and retrieve audio content seamlessly.</div>
                        <div class="work-tags">
                            <div class="tag">0 to 1</div>
                            <div class="tag">Full Product</div>
                            <div class="tag">User Research</div>
                            <div class="tag">UI/UX Design</div>
                            <div class="tag">Usability Testing</div>
                
                            <div class="tag">High-fidility Prototyping</div>
                        </div>
                    </div>
                    <div class="right">
                        <img class="img-work img-work2" src="../assets/images/Synth.png" alt="">
                    </div>
                </div>
            </router-link>
            <router-link class="routerlink_ello" to="/casestudy_ello">
                <div class="work work3">
                    <div class="left">
                        <div class="work-title">Ello.</div>
                        <div class="work-des">A smart home system to help people live a more comfortable secure and nurtured life.</div>
                        <div class="work-tags">
                            <div class="tag">0 to 1</div>
                
                            <div class="tag">User Research</div>
                
                
                            <div class="tag">Rapid Prototyping</div>
                        </div>
                    </div>
                    <div class="right">
                        <img class="img-work img-work3" src="../assets/images/Ello.png" alt="">
                    </div>
                </div>
            </router-link>
        
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style  scoped>
*{
    padding:0;
    margin:0;
    box-sizing:border-box;
}
:global(.body){
    
    font-siz:16px;
    margin:0;
    padding:0;   
    }

    .routerlink_ello{
        text-decoration:none;
        color:inherit;
        cursor:url("../assets/images/viewProject.svg") 50 50,auto;
    }
    .routerlink{
        text-decoration:none;
        color:inherit;
        cursor:url("../assets/images/viewProject_locked.svg") 50 50,auto;
    }
    .top{
        background-colo: var(--bg);
        margin:0px 80px;
        
    }
    .name{
        color: #ededed;

        font-family: hkgrotesk;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2rem; /* 133.333% */
        margin-bottom:1.67rem;
        letter-spacing:0.06rem;
    }

    .prophecy a{
        color:#ededed;
        text-decoration-thickness: 1px;
        text-underline-offset: 3px;
    }
    .prophecy a:hover{
        color:#ededed;
    }
    .header{
        color: #EDEDED;

        font-family: hkgrotesk;
        font-size: 4.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 5.5rem; /* 122.222% */
        margin-bottom:10rem;
    }
    .wrapper{
        background-colo: yellow;;
        position:relative;
        width:100%;
        padding-top:56.25%;
        cursor:url("../assets/images/playShowreel.svg") 50 50,auto;
        
    }
    .rectangle{
        position:absolute;
        background-colo: blue;
        
        
        border-radius: 16px;
        background: rgba(237, 237, 237, 0.10);
        width:100%;
        height:100%;
        top:0;
        
    }
    
    .selectedwork{
        background-colo: yellow;
        margin-top:7.75rem;
        margin-bottom:3rem;
        color: #EDEDED;

        font-family: hkgrotesk;
        font-size: 4.5rem;
        font-style: normal;
        font-weight: 600;
        line-height:5.5rem;
    }
    .line{
        position:absolute;
       
        left: 0;
        border-top:1px solid #fff;
        width:100%;
        opacity:0.10;
        
        
    }
    .works{
        background-colo: blue;
        margin-top:7.5rem;
        display:flex;
        flex-direction:column;
        justify-content: flex-end;
        gap:15.5rem;
    }
    
    .left,
    .right{
        background-colo: red;
        display:flex;
        justify-content: center;
        flex-direction:column;
        gap:.5rem;
        align-items: flex-start;
        
    }
    .img-work{
        width:688px;
        height:auto;
    }
    .work{
        color: #EDEDED;
        display:flex;
        flex-direction:row;
        align-items: flex-between;
        gap:4rem;
        align-content:center;

    }
    .work-title{
        color:#A0A0A2;
        font-size: 2.5rem;
        line-height:3rem;
        font-weight:600;
    }
    .work-des{
        align-self: stretch;
        font-size: 2.5rem;
        line-height:3rem;
        font-weight:600;
    }
    .work-tags{
        display:flex;
        flex-wrap:wrap;
        gap:1rem;
        margin-top:2rem;

    }
    .tag{
        color: #EDEDED;
        background-color: rgba(237, 237, 237, 0.10);
        text-align: center;
        font-family: hkgrotesk;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5rem; /* 150% */
        padding:0.62rem 1rem;
        border-radius:.5rem;
       
    }

    @media screen and (max-width:945px){
        .work1,.work2,.work3{
            flex-direction:column;
        }
        .top{
            margin-left:16px;
            margin-right:16px;
        }
        .img-work1,.img-work2,.img-work3{
        width:100%;
        height:auto;
    
    }
    .header,.selectedwork{
        font-size:3rem;
        line-height:3.5rem;
    }
    }
    @media screen and (min-width:945px) and  (max-width:1550px){
        .header,.selectedwork{
        font-size:3.5rem;
        line-height:4rem;
    }
    }
</style>