<template>

    <div>
        <div class="top">
            <div class="name">
                Play opens up possibilities.
            </div>
            <div class="header">
                I enjoy tinkering with emerging technologies,  new tools and crafts. Engineer in me, often, craves to give life to my crazy ideas.
            </div>
            
            
        </div>
            <div class="video-wrapper">
                <video playsinline autoplay muted loop poster="cake.jpg">
                    <source src="../assets/images/TennisBalls2.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>
            <div class="main-menu">
                <div class="menu-items">
                    
                        <ul v-for="(menuitem,index) in menuitems" :key="index">
                            <li>
                            <div class="menu-container">
                            <div class="menu-title" @click="activatetitle(index)">
                                <div class="title" v-text="menuitem.title"></div>
                        
                                <span :ref="'down'+index" class="chev down active">
                                    <!-- <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" stroke="currentColor" fill="currentColor" stroke-width="10" stroke-linecap="square" stroke-linejoin="bevel" >! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.<path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/></svg> -->
                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" height="1em" viewBox="0 0 512 512" fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10" stroke-width="48"><path  d="M112 328l144-144 144 144"/></svg>
                                </span>
                                <span :ref="'up'+index" class="chev up">
                                    <!-- <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" stroke="currentColor" fill="currentColor" stroke-width="10" stroke-linecap="square" stroke-linejoin="bevel" >! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.<path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg> -->
                                    
                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" height="1em" viewBox="0 0 512 512" fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10" stroke-width="48"><path  d="M112 184l144 144 144-144"/></svg>
                                </span>
                            </div>
                                <div :ref="'content'+index" class="content active">
                                    <div class="images" >
                                        <img v-for="(image,index) in menuitem.images" :key="index" :src="require('../assets/images/'+image+'.png')" alt="">
                                    </div>
                                </div>
                            </div>
                            </li>
                        </ul>
                    
                </div>
            </div>


    </div>
</template>

<script>
    export default {
        data(){
            return{menuitems:[
                {title:'Prototypes.',images:['image334','image335','image336','image337','image338','image339']},
                {title:'Coded App Interfaces.',images:[]},
        ]}
        },
        methods:{
            activatetitle(i){
                console.log(this.$refs)
               
                this.$refs['up'+i][0].classList.toggle('active');
                this.$refs['down'+i][0].classList.toggle('active');
                this.$refs['content'+i][0].classList.toggle('active');
            },
        },
    }
</script>

<style  scoped>
    *{
    padding:0;
    margin:0;
    box-sizing:border-box;
}
    .top{
        background-colo: var(--bg);
        margin:0px 80px;
        
    }
    .name{
        color: #ededed;

        font-family: hkgrotesk;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2rem; /* 133.333% */
        margin-bottom:1.67rem;
        letter-spacing:0.06rem;
        margin-bottom:1.67rem;
    }

    .header{
        position:relative;
        color: #EDEDED;

        font-family: hkgrotesk;
        font-size: 4.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 5.5rem; /* 122.222% */
        margin-botto:10rem;
    }

    video {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        
        }
    .video-wrapper {
        mix-blend-mode: screen;
        border: 2px solid #000;
        width: calc(100vw - calc(100vw - 100%));
        height: 44vw;
        position: absolute;
        top:69.6px;
        overflow: hidden;
        text-alig: right ;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        z-index:-1;
    }
    .main-menu{
        margin-top:300px;
       
    }
    .menu-items{
        

        background-colo: blue;
        display:flex;
        flex-direction:column;
    }
    .menu-container{
        border-bottom:1px solid rgba(237,237,237,.10);
        
    }

    .menu-items .menu-title{
        background-colo:red ;
        margin:0px 80px;
        
        color: #EDEDED;
        padding-bottom:48px;
        padding-top:48px;
        
        font-family: hkgrotesk;
        font-size: 4rem;
        font-style: normal;
        font-weight: 600;
        line-height: 4.5rem; /* 122.222% */
        display:flex;
        justify-content:space-between;
    }
    .main-menu .title{
       margin-lef:80px;
       background-colo: yellow;
    }
    .main-menu .menu-container span {
        background-colo: yellow;
        margin:auto 0px;
        color: #EDEDED;
        transition: all 200ms ease;
        display:flex;
    }
    .main-menu .menu-container span svg {
     margin-top:0px;
    }

.main-menu .menu-container  .up.active {
  display:none;
} 
.main-menu .menu-container  .down.active {

  display:none;
} 

.main-menu .menu-items ul {
  
  list-style: none;
  padding: 0;
  margin: 0;
  
}



.main-menu .menu-container  {
  backgroun: #edf6f9;
}

.main-menu .menu-container .content{
    margin:24px 80px 72px 80px;
    background-colo: red;
    display:block;
}

.images{
    display:flex;
    flex-wrap:wrap;
    gap:24px;
    flex-flow: center;
}

.images img{
    width:25vw;
    max-width:fit-content;
    height:auto;
    flex-grow:1;

}
.main-menu .menu-container .content.active{
    display:none;
}
    @media screen and (max-width:1400px){
        .video-wrapper {
            display:none;
        }


    }

    @media screen and (max-width:945px){
        .header,.menu-items .menu-title{
        font-size:3rem;
        line-height:3.5rem;
    }
        .images img{
            width:100%;
        }
        .top{
            margin-left:16px;
            margin-right:16px;
        }
        .main-menu{
            margin-top:100px;
       
        }
        .menu-items .menu-title{
        margin:0px 16px;
        padding:24px 0px;
            
        }
        .main-menu .menu-container .content{
            margin:24px 16px 72px 16px;
        }
    }
    @media screen and (min-width:945px) and  (max-width:1550px){
        .header,.menu-items .menu-title{
        font-size:3.5rem;
        line-height:4rem;
    }
    }
</style>