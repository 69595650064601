<template>
    <div>
        <div ref="myoverlay" class="overlay">
            <!-- <a href="javascript:void(0)" class="closebtn" @click="closeoverlay">&times;</a> -->
            <a href="javascript:void(0)" class="closebtn" @click="closeoverlay"><img src="../assets/images/close_icon.png" width="31.5" alt=""></a>
            <div class="overlay-content"> 
                <a href="#"  @click.prevent="workclicked">Work.</a>
                <a href="#"  @click.prevent="playclicked">Play.</a>
                <a href="#" @click.prevent="personclicked">Person.</a>
                <a href="#" @click.prevent="connectclicked">Connect.</a>
                
            </div>
        </div>
        <nav class="main-menu">
            <div class="main-items">
                <div class="logo">
                    <a href="#" @click.prevent="logoclicked"><img src="../assets/images/logo_1.png" alt=""></a>
                </div>

                <div class="menu-items">
                    <div class="menu-title">
                        <a href="#" class="work" @click.prevent="workclicked">Work.</a>
                    </div>
                    <div class="menu-title">
                        <a href="#" class="play" @click.prevent="playclicked">Play.</a>
                    </div>
                    <div class="menu-title">
                        <a href="#" class="person" @click.prevent="personclicked">Person.</a>
                    </div>
                    <div class="menu-title">
                        <div class="connect" @click.prevent="connectclicked">Connect.</div>
                    </div>
                </div>
                <div class="navopen" ref="navopen" @click="openoverlay">&#9776;</div>
            </div>
        </nav>
        <div class="main-content">
          <slot></slot>

        </div>
    </div>
</template>

<script>
    
    export default {

        methods:{
            openoverlay(){
                this.$refs.myoverlay.style.width='100%';
            },
            closeoverlay(){
                this.$refs.myoverlay.style.width='0%';
            },
           logoclicked(){
                this.$emit('logoclicked')
                this.$router.push('/')
                this.closeoverlay()
            },
            workclicked(){
                //this.$emit('workclicked')
                this.$router.push('/works')
                this.closeoverlay()
            },
            playclicked(){
                //this.$emit('workclicked')
                this.$router.push('/play')
                this.closeoverlay()
            },
            personclicked(){
                this.$emit('personclicked')
                this.$router.push('/person')
                this.closeoverlay()
            },
            connectclicked(){

                //this.$emit('connectclicked')
                //this.$router.push('/connect')
                this.closeoverlay()
                window.location.href="mailto:aggarwal.aakash@gmail.com?subject=Hello%20Aakash&amp;"
            }
        }
        
    }
</script>

<style  scoped>
*{
    font-family: hkgrotesk;
    margin:0;
    padding:0;
}
.main-menu{
    background-color: rgba(23, 23, 23, 0.90);
    color:#fff;
    font-family: hkgrotesk;
    font-weight:500;
    backdrop-filter: blur(24px);
    position:fixed;
    right:0;
    left:0;
    top:0;
    opacity:.97;
    z-index:500;
    padding-right:20px;
    padding-left:30px;
    border-bottom: 1px solid rgba(237,237,237,.1);
}
nav .main-items{
display: flex;
widt: 1728px;
paddin: 16px 144px;
justify-content:space-between;
align-items: center;
}
nav .menu-items{
    background-colo: red;
    display:flex;
    justify-content:flex-end;
    padding:0px 20px;
    align-items:center;
    

}
nav .connect{
    font-family:inherit !important;
    font-size:1rem !important;
    font-weight:700;
    line-height:1.5rem;
    padding:.62rem 1rem;
    cursor:pointer;
}

nav .logo{
    background-colo: yellow;
    padding:0px 50px;
       
}

nav .logo img{
    width:93px;
    height:auto;
}
nav .logo a{
    display:flex;
    
}
nav .logo img:hover{
    background-color: rgba(237, 237, 237, 0.10);
    border-radiu:.5rem;
}
nav .menu-title{
    
    padding:10px 40px 10px 8px;
    
    
     
}
nav .menu-title a{
    text-decoration:none;
    color: #ededed;
    

}
nav .menu-title a:hover{
    text-decoration:underline;
    text-underline-offset: 3px;
}
nav .menu-title button{
    cursor:pointer;
}
nav .menu-title .connect{

    text-align: center;
    font-family: hkgrotesk;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    background-color: #ededed;
    border-radius:.5rem;
    color:#171717;
}

nav .menu-title .connect:hover{
    background-color: #dfdfdf;
}

.main-content{
    margin:0px 0px;
}

nav .navopen{
    display:none;
}
    @media screen and (max-width:945px){
        nav .logo img{
            width:73px;
            height:auto;
        }
        nav .menu-items{
            display:none;
        }
        nav .navopen{
            display:block;
            font-size:24px;
            margin-right:80px;
            cursor:pointer;
        }
    }

 /* The Overlay (background) */
 .overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */   
  height: 100%;
  width: 0;
  position: fixed; /* Stay in place */
  z-index: 800; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0,0,0); /* Black fallback color */
  background-color: rgba(0,0,0, 1); /* Black w/opacity */
  backdrop-filter: blur(24px);
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  opacity:.9;
}

/* Position the content inside the overlay */
.overlay-content {
  position: relative;
  top: 25%; /* 25% from the top */
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}

/* The navigation links inside the overlay */
.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  font-weight:600;
  color: #ededed;
  display: block; /* Display block instead of inline */
  transition: 0.3s; /* Transition effects on hover (color) */
}

/* When you mouse over the navigation links, change their color */
.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

/* Position the close button (top right corner) */
.overlay .closebtn {
  position: absolute;
  top: 0px;
  right: 88px;
  font-size: 50px;
}

/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
@media screen and (max-height: 450px) {
  .overlay a {font-size: 20px}
  .overlay .closebtn {
    font-size: 50px;
    top: 15px;
    right: 35px;
  }
} 

@media screen and (max-width:945px){
    nav .logo{
        background-colo: red;
        padding:0px 0px;
        margin:0px -15px;
    }
    nav .navopen{
        background-colo: red;
        
        margin-right:0px;
    }
    .overlay .closebtn {
        position: absolute;
        top: 2px;
        right: 10px;
        
}
}
</style>