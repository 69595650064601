<template>
  <div style="min-height:100vh; display:flex; flex-direction:column; 
            justify-content:space-between;">
    <topnavbar @logoclicked="logoclicked" @personclicked="personclicked" @connectclicked="connectclicked" workclicked="workclicked">
      <div class="content">
        <!-- <component :is="c" :key="ck"></component> -->
        <router-view></router-view>
      </div>
    </topnavbar>
    <footer class="footer">
      

        <div class="left">
          <div class="a a1"><a href="https://in.linkedin.com/in/aggarwal-aakash " target="_blank">LinkedIn</a></div>
          <div class="a a2"><a href="https://www.goodreads.com/aakashaggarwal" target="_blank">Goodreads</a></div>
          <div class="a a3"><a href="https://www.instagram.com/aakash.aggarwal.94/" target="_blank">Instagram</a></div>
        </div>

        <div class="right">
          <div>© Aakash Aggarwal 2024 </div>
        </div>
      
    </footer>

  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
 import topnavbar from './components/topnavbar.vue'
// import landingpage from './components/landingpage.vue'
// import works from './components/works.vue'
// import person from './components/person.vue'
// import connect from './components/connect.vue'
// import casestudy_loop from './components/casestudy_loop.vue'
export default {
  name: 'App',
  data(){return {c:'',ck:0,root:null,}},
  components: {
    //HelloWorld
    topnavbar,
    //landingpage,casestudy_loop,works,person,connect,
  },
  mounted(){
    //console.log(this.landingpage)
    //this.c=landingpage;
    this.ck+=1;
    this.root=document.documentElement;
  },
  methods:{
    logoclicked(){
      console.log('logo clicked');
      //this.c=landingpage;
      this.ck+=1;
    },
    workclicked(){
      console.log('work clicked');
      //this.c=works;
      this.ck+=1;
    },
    personclicked(){
      console.log('person clicked');
      //this.c=person;
      this.ck+=1;
    },
    connectclicked(){
      console.log('connect clicked');
      //this.c=connect;
      this.ck+=1;
    },
  }
}
</script>

<style>
:root{
  --bg:white;
}
body{
  background-color: #171717;
}
.content{
    margin-top:15.5rem;
  }
@font-face {
  
  font-family: "hkgrotesk";
  src: local("hkgrotesk"),   url(./assets/fonts/hk-grotesk/HKGrotesk-Regular.otf) format("truetype");
}
@font-face {
  
  font-family: "wilderness";
  src: local("wilderness"),   url(./assets/fonts/WildernessTypeface-Regular.ttf) format("truetype");
}
  .footer {
    
    background-color: rgba(237,237,237,.10);
    margin-top:15.5rem;
    color: #A0A0A2;

    font-family: hkgrotesk;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
    text-align:center;
    padding:10px 16px 10px 16px;
    margin-bottom:0px;
}


/* html { font-size:16px;} */


  /* html { font-size: calc( (16px + (24 - 16) * (100vw - 400px) / (800 - 400))/2 ); } */
  html {
    /* font-size: calc(16px + 0.390625vw); */
    font-size: 16px;
  }

   @media screen and (max-width:945px){
    html { font-size:12px;}
  } 

  .footer {
    display:flex;
    justify-content:space-between;
    align-items:center;
  }

  .footer .left{
    display:flex;
    gap:20px;
    
    
  }

  .footer .left, .footer .right{
    margin:0px 80px;
  }

  .footer .left .a:hover{
    text-decoration:underline;
    cursor:pointer;
  }
  .footer a{
    text-decoration:none;
    color: #A0A0A2;
    }
    @media screen and (max-width:787px){
      .footer{
        flex-direction:column;
        gap:1rem;
        padding:10px 16px 24px 16px;
      }
      .footer .left, .footer .right{
        margin:0px 0px;
      }
    }
</style>
