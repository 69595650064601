<template>
  <div class="op">
    <div class="work work3">
      <div class="left">
        <div class="work-title">{{worktitle}}</div>
        <div class="work-des">
          {{ workdes }}
        </div>
        <div class="work-tags">
            <div v-for="tag,index in tags" class="tag" v-text="tag" :key="index"></div>
        </div>
      </div>
      <div class="right">
        <img
          class="img-work img-work1"
          :src="require('../assets/images/'+ imgsource)"
          
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name:'work',
        props:{
            worktitle:{
                type:String,
                default: ''
            },
            workdes:{
                type:String,
                default: ''
            },
            tags:{
                type:Array,
                default: function(){return []}
            },
            imgsource:{
                type:String,
                default: ''
            },


        },
        computed:{
            workimage(){
                const img="'"+this.imgsource+"'"
                console.log(img)
                return this.imgsource
            },
        },

    };
</script>

<style scoped>
    *{
    padding:0;
    margin:0;
    box-sizing:border-box;
}
:global(.body){
    background-colo: rgba(23, 23, 23, 0.90);
    font-size:16px;
    margin:0;
    padding:0;   
    }

    .top{
        background-colo: red;
        margin:0px 0px;
        
    }
    
    .works{
        background-colo: blue;
        margin-top:7.5rem;
        display:flex;
        flex-direction:column;
        justify-content: flex-end;
        gap:15.5rem;
    }
    
    .left,
    .right{
        background-colo: red;
        display:flex;
        justify-content: center;
        flex-direction:column;
        gap:.5rem;
        align-items: flex-start;
        
    }
    .img-work{
        width:688px;
        height:auto;
    }
    .work{
        color: #EDEDED;
        display:flex;
        flex-direction:row;
        align-items: flex-between;
        gap:4rem;
        align-content:center;

    }
    .work-title{
        color:#A0A0A2;
        font-size: 2.5rem;
        line-height:3rem;
        font-weight:600;
    }
    .work-des{
        align-self: stretch;
        font-size: 2.5rem;
        line-height:3rem;
        font-weight:600;
    }
    .work-tags{
        display:flex;
        flex-wrap:wrap;
        gap:1rem;
        margin-top:2rem;

    }
    .tag{
        color: #EDEDED;
        background-color: rgba(237, 237, 237, 0.10);
        text-align: center;
        font-family: hkgrotesk;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5rem; /* 150% */
        padding:0.62rem 1rem;
        border-radius:.5rem;
       
    }

    @media screen and (max-width:945px){
        .work1,.work2,.work3{
            flex-direction:column;
        }
        .to{
            margin-left:16px;
            margin-right:16px;
        }
        .img-work1,.img-work2,.img-work3{
        width:100%;
        height:auto;
    
    }
    }

</style>
