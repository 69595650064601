<template>
    <div class="top">
        <div class="wrapper">
            <div class="name">
                A little about me.
            </div>
            <div class="about">
                I’m a Product Designer and a UI Engineer with a background in Interaction Design and Mechanical Engineering.
            </div>
            <div class="images">
                <div class="img img1">
                    <img src="../assets/images/himachal.png" alt="">
                </div>
                <div class="img img2">
                    <img src="../assets/images/Drone_vector_Sticker.png" alt="">
                </div>
                <div class="img img3">
                    <img src="../assets/images/kerala.png" alt="">
                </div>
            </div>
            <div class="buttonsabout2">
            <div class="about2">
                <p>I'm a designer who loves to discover and solve — through research and rapid experimentation — problems that truly matter. I find satisfaction in simplifying people‘s lives by creating products that are fun, easy to use and aesthetically pleasing.</p>
                <p>Currently, as an Senior Product Designer at <span>Prophecy</span>, I am helping emerging startups craft innovative product experiences that work well for people and businesses.</p>
                <p>I have previously worked as a Lead Product Designer at Synth and Renegade Insurance. Additionally, I have worked for companies like Generali, Stryker, Siemens Healthineers, OYO, Danfoss on digital products spanning insurance, healthcare, hospitality and technology.</p>
                <p>I'm an alumnus of <span>Copenhagen Institute of Interaction Design.</span></p>
                <p>Outside of work, I enjoy traveling to culturally rich places, reading historical fiction, and playing action-adventure video games.</p>
            </div>
            
                <div class="buttonswrapper">
                    <div class="buttons">
                            <div class="btn btn1" @click="getresume">View My Resume.</div>
                            <div class="btn btn2" @click="getintouch">Let's Get In Touch.</div>
                    </div>
                </div>
            </div>
            
        </div>
        
    </div>
</template>

<script>
    export default {
        methods:{
            getintouch(){
                window.location.href="mailto:aggarwal.aakash@gmail.com?subject=Hello%20Aakash&amp;"
            },
            getresume(){
                window.open("https://www.dropbox.com/sh/640hc8ny66h3cel/AACUV-dvxmI_lLN1MRwTQNzia?dl=")
            },
        },
    }
</script>

<style  scoped>
*{
    padding:0;
    margin:0;
    box-sizing:border-box;
}
:global(.body){
    background-colo: rgba(23, 23, 23, 0.90);
    font-size:1rem;
    margin:0;
    padding:0;   
    }
    .top{
        background-colo: red;
        margin:0px 80px;
    }
    .wrapper{
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items: stretch;
        flex-gro:1;
        flex-shrin:1;
    }
    .name{
        color: #ededed;

        font-family: hkgrotesk;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2rem; /* 133.333% */
        margin-bottom:1.67rem;
        letter-spacing:0.06rem;
        margin-bottom:1.67rem;
        align-self:flex-start;
    }
    .about{
        color: #EDEDED;

    font-family: hkgrotesk;
    font-size: 4.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 5.5rem; /* 122.222% */
    
    }

    .images{
        margin-top:12rem;
        display:flex
    }
    .img3{
        background-colo: red;
        padding-left:66px;
        padding-right:66px;
        padding-top:6rem;
        
    }
    .img2{
        background-colo: red;
        margin-left:-60px;
        margin-top:20px;
    }
    .img1{
        background-colo: blue;
    }

    .img1 img,.img2 img,.img3 img{
        width:100%;
        height:auto;
    }

    .buttonsabout2{
        background-colo: yellow;
        
        
        
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        
    }
    .about2{
        background-colo: red;
        color: #EDEDED;
        margin-top:4rem;
        font-family: hkgrotesk;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.75rem; /* 140% */
        max-width:40rem;
        height:auto;
        
        
    }
    .about2 p{
        margin-top:20px;
        font-weight: 400;
        
        
        
        
    }

    .about2 p span{
        color: #ededed;
        font-weight:800;
    }
    
    .buttonswrapper{
        background-colo: green;
        width:100%;
        max-width:40rem;
    }
    .buttons{
        background-colo: blue;
        display: flex ;
        margin-top:4rem;
        margin-bottom:0px;
        justify-content:space-between;
        gap:1rem;
        width:100%;
        
        
    }
    
    .buttons .btn{
        text-align:center;
        width: 100%;
         padding: 10px 16px; 
        cursor:pointer;
        
        
    }
    .buttons .btn1{
        text-align: center;
        font-family: hkgrotesk;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 150% */
        color: #EDEDED;
        border-radius: .5rem;
        border: 1px solid rgba(237, 237, 237, 0.20);

   
        
    }
    .buttons .btn1:hover{
        background-color:rgb(237,237,237,.02) ;
    }
    .buttons .btn2{
        text-align: center;
        font-family: hkgrotesk;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 150% */
        background-color: #ededed;
        border-radius:.5rem;
        color:#171717;
    }
    .buttons .btn2:hover{
        background-color:#dfdfdf ;
    }

    @media screen and (max-width:945px){
        .top{
            margin:0px 16px;
        }
        .about{
            font-size:3rem;
            line-height:3.5rem;
        }
        .images{
        margin:50px;
        display:flex;
        flex-direction: column;
    }
    .img3{
        padding-left:0px;
        padding-right:0px;
        padding-top:0rem;
        margin-top:40px;
    }
    .img2 img{
        
        display:none;
        width:30%;
        height:auto;
    }
    .about2{
        width:100%;
        height:auto;
        margin-top:0rem;
       
    }
    .about2 p{
        font-size:1.5rem;
        line-height:2rem;
    }
    .buttons{
        background-colo: blue;
        flex-direction:column;
        
        justify-content:space-between;
        gap:1rem;
        width:100%;
        
        
    }

    }
    @media screen and (min-width:945px) and  (max-width:1550px){
        .about{
        font-size:3.5rem;
        line-height:4rem;
    }
    }
    @media screen and (min-width:1300px){
        .about{
            background-colo: red;
            padding-right:80px;
        }
    }
</style>