import Vue from 'vue/dist/vue.js';
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import landingpage from '../components/landingpage.vue'
import works from '../components/works.vue'
import play from '../components/play.vue'
import person from '../components/person.vue'
import connect from '../components/connect.vue'
import casestudy_loop from '../components/casestudy_loop.vue'
import casestudy_ello from '../components/casestudy_ello.vue'


const routes = [
  { path: '/', components: {default:landingpage, }},
  { path: '/works', components: {default:works, }},
  { path: '/play', components: {default:play, }},
  { path: '/person', components: {default:person, }},
  { path: '/connect', components: {default:connect, }},
  { path: '/casestudy_loop', components: {default:casestudy_loop, }},
  { path: '/casestudy_ello', components: {default:casestudy_ello, }},
  
]
const router = new VueRouter({
  routes // short for `routes: routes`
})

export default router