<template>
    <div class="top">
        <div class="name">
            Work. Work. Work.
        </div>
        <div class="header">
            My work explores design to envision or enhance meaningful experiences for people that lead to positive business outcomes.
        </div>
        <div class="line"></div>
        <div class="works">
            <router-link class="routerlink" to="">
                <work
                    worktitle="Loop."
                    workdes="A novel and differentiated offering in the red-ocean of primary healthcare."
                    :tags="['0 to 1','Growth Strategy','User Research','UI/UX Design','Interaction Design','Storytelling','High-fidility Prototyping']"
                    imgsource="Loop.png"
                >
                </work>
            </router-link>
            <router-link class="routerlink" to="">
                <work
                    worktitle="Synth."
                    workdes="Tool that helps people capture, organise and retrieve audio content seamlessly."
                    :tags="['0 to 1','Full Product','User Research','UI/UX Design','Usability Testing',]"
                    imgsource="Synth.png"
                >
                </work>
            </router-link>
            <router-link class="routerlink" to="/casestudy_ello">
            <work
                worktitle="Ello."
                workdes="A smart home system to help people live a more comfortable secure and nurtured life."
                :tags="['0 to 1','User Research','Rapid Prototyping',]"
                imgsource="Ello.png"
            >
            </work>
            </router-link>
            <router-link class="routerlink" to="">
                <work
                    worktitle="Mezink."
                    workdes="Control centre for creators that empowers them to manage their digital presence on the go."
                    :tags="['0 to 1','Proof of Concept','User Interface Design']"
                    imgsource="Mezink.png"
                >
                </work>
            </router-link>
            <router-link class="routerlink" to="">
                <work
                    worktitle="CoI Tracker."
                    workdes="An affordable and better way for small businesses to manage and track insurance certificates."
                    :tags="['0 to 1','Minimum Viable Product','User Research','UI/UX Design','Rapid Prototyping',]"
                    imgsource="Coltracker.png"
                >
                </work>
            </router-link>
            <router-link class="routerlink" to="">
                <work
                    worktitle="CGHS Platforms."
                    workdes="Making access to health insurance and healthcare providers easy for Generali Global Health members."
                    :tags="['UI/UX Design','Design System',]"
                    imgsource="Generali.png"
                >
                </work>
            </router-link>
            <router-link class="routerlink" to="">
                <work
                    worktitle="Smart ICU Bed."
                    workdes="A holistic, human-centred approach to managing care on ICU beds using IoT in hospitals."
                    :tags="['UI/UX Design','Prototyping with Arduino',]"
                    imgsource="Stryker.png"
                >
                </work>
            </router-link>
            <router-link class="routerlink" to="">
                <work
                    worktitle="MyVisiApp."
                    workdes="A weather app integrated with maps to prevent fog related road accidents."
                    :tags="['School Project','Graphical User Interface','Rapid Prototyping',]"
                    imgsource="Visiapp.jpg"
                >
                </work>
            </router-link>
            <router-link class="routerlink" to="">
                <work
                    worktitle="LEGOVision."
                    workdes="Creating a gaming experience at the intersection of physical and digital worlds."
                    :tags="['School Project','Tangible User Interface','Rapid Prototyping',]"
                    imgsource="Lego.jpg"
                >
                </work>
            </router-link>
        </div>
    </div>
</template>

<script>
import work from '../components/work.vue'
    export default {
        components:{
            work,
        },
    }
</script>

<style  scoped>
    .top{
        background-colo: var(--bg);
        margin:0px 80px;
        
    }
    .name{
        color: #ededed;

        font-family: hkgrotesk;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2rem; /* 133.333% */
        margin-bottom:1.67rem;
        letter-spacing:0.06rem;
        margin-bottom:1.67rem;
    }

    .header{
        color: #EDEDED;

        font-family: hkgrotesk;
        font-size: 4.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 5.5rem; /* 122.222% */
        margin-bottom:12.5rem;
    }

    .line{
        position:absolute;
       
        left: 0;
        border-top:1px solid #fff;
        width:100%;
        opacity:0.10;
        
        
    }
    .routerlink{
        text-decoration:none;
        color:inherit;
        cursor:url("../assets/images/viewProject_locked.svg") 50 50,auto;
    }
    .works{
        background-colo: blue;
        margin-top:17rem;
        display:flex;
        flex-direction:column;
        justify-content: flex-end;
        gap:15.5rem;
    }

    @media screen and (max-width:945px){
        
        .top{
            margin-left:16px;
            margin-right:16px;
        }
        .header{
        font-size:3rem;
        line-height:3.5rem;
    }
    }

    @media screen and (min-width:945px) and  (max-width:1550px){
        .header{
        font-size:3.5rem;
        line-height:4rem;
    }
    }
</style>