<template>
    <div class="top">
        <div ref="myoverlay" class="overlay" @click="closeoverlay">
            <a href="javascript:void(0)" class="closebtn" @click="closeoverlay">&times;</a>
            <div class="overlay-content">
                <div class="overlay-img">
                    <img src="../assets/images/Ello-3.png" alt="">
            
                </div>

                
            </div>
        </div>
        <div class="wrapper">
            <div class="img1">
                <img src="../assets/images/Ello-2.png" alt="" >
            
            </div>
            <div class="work work3">
                <div class="left">
                    <div class="work-title">Ello</div>
                    <div class="work-des">A smart home system to help people live a more comfortable secure and nurtured life.</div>
                    <div class="work-tags">
                        <div class="tag">0 to 1</div>
            
                        <div class="tag">User Research</div>
            
            
                        <div class="tag">Rapid Prototyping</div>
                    </div>
                </div>
                <div class="about2">
                    <p>I'm a designer who loves to discover and solve — through research and rapid experimentation — problems that truly matter. I find satisfaction in simplifying people‘s lives by creating products that are fun, easy to use and aesthetically pleasing.</p>
                    <p>Currently, as an Senior Product Designer at <b>Prophecy</b>, I am helping emerging startups craft innovative product experiences that work well for people and businesses.</p>
                    <p>I have previously worked as a Lead Product Designer at Synth and Renegade Insurance. Additionally, I have worked for companies like Generali, Stryker, Siemens Healthineers, OYO, Danfoss on digital products spanning insurance, healthcare, hospitality and technology.</p>
                    <p>I'm an alumnus of <b>Copenhagen Institute of Interaction Design.</b></p>
                    <p>Outside of work, I enjoy traveling to culturally rich places, reading historical fiction, and playing action-adventure video games.</p>
                </div>
                <div class="img2">
                    <img src="../assets/images/Ello-3.png" alt="" @click="openoverlay">
            
                </div>
                
            </div>
        </div>
        <div class="crayfun">Cray fun !</div>
        <div class="cool">This was so cool
            <span><svg xmlns="http://www.w3.org/2000/svg" width="51" height="13" viewBox="0 0 51 13" fill="none">
                        <path d="M46.1141 9.19747C42.4705 9.01942 39.0795 8.83549 35.6805 8.69866C35.4279 8.70453 35.1846 8.95415 34.5749 9.28735C36.109 9.73963 37.2927 10.1813 38.5081 10.4345C41.7218 11.073 44.9026 11.609 48.1322 12.1532C48.5237 12.2192 48.9072 12.3322 49.3145 12.304C50.6265 12.2829 51.2018 11.5564 50.6993 10.3576C50.4441 9.78181 50.132 9.24484 49.763 8.74672C48.3691 6.86494 46.9184 5.02202 45.4676 3.17909C45.2216 2.84701 44.805 2.6315 44.2734 2.20284C43.7707 4.58847 46.1552 5.66842 46.376 7.64315C45.9515 7.47473 45.5679 7.36167 45.2413 7.20974C32.6165 1.49789 19.3898 -0.343471 5.704 0.837838C4.2783 0.936654 2.86977 1.23211 1.46919 1.48048C0.744428 1.60053 0.0699122 2.0197 0.326455 2.88637C0.534063 3.7448 1.21914 3.86025 2.05764 3.66248C11.4518 1.37047 20.8206 2.5133 30.1921 4.23783C35.0776 5.10953 39.7858 6.43574 44.3498 8.31891C44.9132 8.55917 45.4687 8.84653 46.1141 9.19747Z" fill="#EDEDED"/>
                    </svg>
            </span>
        </div>
    </div>
</template>

<script>
//import work from './work.vue'
    export default {
        components:{
            //work,
        },
        methods:{
            openoverlay(){
                console.log(this.$refs.myoverlay.style.transform);
                this.$refs.myoverlay.style.scale=.9
                //this.$refs.myoverlay.style.width='100%';
                //this.$refs.myoverlay.style.height='100%';
            },
            closeoverlay(){
                this.$refs.myoverlay.style.scale=0
                //this.$refs.myoverlay.style.width='0%';
                //this.$refs.myoverlay.style.height='0%';
            },
        },
    }
</script>

<style  scoped>
*{
    padding:0;
    margin:0;
    box-sizing:border-box;
}
:global(.body){
    background-colo: rgba(23, 23, 23, 0.90);
    font-siz:1rem;
    margin:0;
    padding:0;   
    }
    .top{
        background-colo: red;
        margin:0px 0px;
    }
    .img1{
        margin:0px 80px;
    }
    .img1 img{
        width:100%;
        height:auto;
        
    }

    

    .work{
        background-colo: yellow;
        display:flex;
        flex-direction:column;
        justify-content:space-evenly;
        align-items:center;
        color: #EDEDED;
        max-width:704px;
        margin-left:auto;
        margin-right:auto;
        

    }

    .left{
        background-colo: red;
        display:flex;
        justify-content: center;
        flex-direction:column;
        gap:12px;
        align-items: flex-start;
        
    }
    .work-title{
        color:#A0A0A2;
        font-size: 3rem;
        font-weight: 600;
        line-height: 3.5rem; /* 112.5% */
    }
    .work-des{
        align-self: stretch;
        font-size: 2.5rem;
    }
    .work-tags{
        display:flex;
        flex-wrap:wrap;
        gap:10px;

    }
    .tag{
        color: #EDEDED;
        background-color: rgba(237, 237, 237, 0.10);
        text-align: center;
        font-family: hkgrotesk;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5rem; /* 150% */
    }

    .about2{
        background-colo: red;
        color: #EDEDED;

        font-family: hkgrotesk;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem; /* 140% */
        width:100%;
        height:auto;
        align:center;
    }
    .about2 p{
        margin-top:20px;
    }

    .img2{
        margin:40px 0px;
        position:relative;
    }

    .img2 img{
        width:100%;
        height:auto;
        
    }
    .cool{
        position:absolute;
        left:80px;
        top:1400px;
        color: #EDEDED;

        font-family: wilderness;
        font-size: 4rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

     /* The Overlay (background) */
 .overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */   
  height: 100%;
  width: 100%;
  scale:0;
  position: fixed; /* Stay in place */
  z-index: 800; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0,0,0); /* Black fallback color */
  background-color: rgba(0,0,0, 0.9); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transitio: 0.5s ease-in; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* Position the content inside the overlay */
.overlay-content {
  position: relative;
  top: 10%; /* 25% from the top */
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  margin-top: 0px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}

/* The navigation links inside the overlay */
.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block; /* Display block instead of inline */
  transition: 0.3s; /* Transition effects on hover (color) */
}

/* When you mouse over the navigation links, change their color */
.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

/* Position the close button (top right corner) */
.overlay .closebtn {
  position: absolute;
  top: 5px;
  right: 88px;
  font-size: 40px;
}

.overlay-content{
    padding:10px 50px;
}
.overlay-img img{
    width:100%;
    height:auto;
}

/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
@media screen and (max-height: 450px) {
  .overlay a {font-size: 20px}
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
} 
</style>